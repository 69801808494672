import React from 'react';
import Layout from '../../../components/Layout';
import NavOne from '../../../components/NavOne';
import { Spoiler } from '@mantine/core';
import PageHeader from '../../../components/PageHeader';
import Footer from '../../../components/Footer';

import g1 from '../../../assets/images/sixty/health-walk/1.jpeg';
import g2 from '../../../assets/images/sixty/health-walk/2.jpeg';
import g3 from '../../../assets/images/sixty/health-walk/3.jpeg';

const BlogLayout = () => {
  return (
    <section className='gallery-one'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='course-details__top-left'>
              <h2 className='course-details__title'>Health Walk</h2>
            </div>
          </div>
          <Spoiler maxHeight={280} hideLabel='Hide' showLabel='Show More'>
            <div className='row'>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g1} alt='' />
                  <a href={g1} className='gallery-one__popup img-popup'>                    
                    <i className="kipso-icon-magnifying-glass"></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g2} alt='' />
                  <a href={g2} className='gallery-one__popup img-popup'>                    
                    <i className="kipso-icon-magnifying-glass"></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g3} alt='' />
                  <a href={g3} className='gallery-one__popup img-popup'>                    
                    <i className="kipso-icon-magnifying-glass"></i>
                  </a>
                </div>
              </div>
            </div>
          </Spoiler>
        </div>
      </div>
    </section>
  );
};

const EventPage = () => {
  return (
    <Layout pageTitle='Event Gallery | LUTH'>
      <NavOne />
      <PageHeader title='' />
      <BlogLayout />
      <Footer />
    </Layout>
  );
};

export default EventPage;
